.tp-bg-1 {
    background: url('../../assets/tumai-pay/bg-1.png') no-repeat top left/cover;
}

.tp-bg-2 {
    background: url('../../assets/tumai-pay/bg-2.png') no-repeat top
        center/cover;
}

.tp-bg-3 {
    background: url('../../assets/tumai-pay/bg-3.png') no-repeat top
        center/cover;
}

.tp-gradient-bg {
    background-image: linear-gradient(
        to right top,
        #ffffff,
        #f9f9f9,
        #f3f3f3,
        #ecedee,
        #e6e7e8
    );
}

.tp-title {
    font-size: 3.75rem;
}

.tp-image-50 {
    width: 50%;
    height: 50%;
}

@media screen and (max-width: 768px) {
    .tp-title {
        font-size: 2.5rem;
    }

    .tp-image-50 {
        width: 100%;
        height: 100%;
    }
}
