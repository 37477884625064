.timeline_area {
  position: relative;
  z-index: 1;
}
.single-timeline-area {
  position: relative;
  z-index: 1;
  padding-left: 40px;
}
@media only screen and (max-width: 575px) {
  .single-timeline-area {
    padding-left: 100px;
  }
}
.single-timeline-area .timeline-date {
  position: absolute;
  width: 40px;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -ms-grid-row-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  /* padding-right: 60px; */
}
@media only screen and (max-width: 575px) {
  .single-timeline-area .timeline-date {
    width: 100px;
  }
}
.single-timeline-area .timeline-date::after {
  position: absolute;
  width: 3px;
  height: 100%;
  content: "";
  background-image: linear-gradient(
    to right,
    #f28907,
    #ed840a,
    #e97f0c,
    #e47a0e,
    #df750f,
    #db7111,
    #d76c12,
    #d36813,
    #ce6314,
    #c95e15,
    #c45a16,
    #bf5517
  );
  top: 0;
  right: 30px;
  z-index: 1;
}
.single-timeline-area .timeline-date::before {
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-image: linear-gradient(
    to right,
    #f28907,
    #ed840a,
    #e97f0c,
    #e47a0e,
    #df750f,
    #db7111,
    #d76c12,
    #d36813,
    #ce6314,
    #c95e15,
    #c45a16,
    #bf5517
  );
  content: "";
  top: 0;
  right: 21px;
  z-index: 5;
  margin-top: -5.5px;
}
.single-timeline-area .timeline-date p {
  margin-bottom: 0;
  color: #020710;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 500;
}
.single-timeline-area .single-timeline-content {
  position: relative;
  z-index: 1;
  /* padding: 30px 30px 25px; */
  border-radius: 6px;
  /* margin-bottom: 15px;
    margin-top: 18px; */
  /* -webkit-box-shadow: 0 0.25rem 1rem 0 rgba(47, 91, 234, 0.125); */
  /* box-shadow: 0 0.25rem 1rem 0 rgba(47, 91, 234, 0.125); */
  /* border: 1px solid #ebebeb; */
}
@media only screen and (max-width: 575px) {
  .single-timeline-area .single-timeline-content {
    padding: 20px;
  }
}
.single-timeline-area .single-timeline-content .timeline-icon {
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
  width: 30px;
  height: 30px;
  background-color: #f1c40f;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 30px;
  flex: 0 0 30px;
  text-align: center;
  max-width: 30px;
  border-radius: 50%;
  margin-right: 15px;
}
.single-timeline-area .single-timeline-content .timeline-icon i {
  color: #ffffff;
  line-height: 30px;
}
.single-timeline-area .single-timeline-content .timeline-text h4 {
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
}
.single-timeline-area .single-timeline-content .timeline-text p {
  font-size: 13px;
  margin-bottom: 0;
}

.kiosk-hr {
  height: 2px;
}

.kiosk-location-card {
  height: 180px;
}

.kiosks-bg-1 {
  background: url("../../assets/kiosks/1.png") no-repeat bottom right/cover;
  /* min-height: 95vh; */
}

.kiosks-bg-2 {
  background: url("../../assets/kiosks/2.jpg") no-repeat top left/cover;
  min-height: 95vh;
}

.kiosks-bg-3 {
  background: url("../../assets/kiosks/3.jpg") no-repeat bottom center/cover;
  min-height: 95vh;
  opacity: 0.8;
}
.kiosks-bg-4 {
  background: url("../../assets/kiosks/4.jpg") no-repeat top left/cover;
  min-height: 95vh;
}
.kiosks-bg-5 {
  background: url("../../assets/kiosks/4.jpg") no-repeat top left/cover;
  min-height: 95vh;
}

.kiosks-bg-6 {
  background: url("../../assets/kiosks/6.png") no-repeat top left/cover;
  min-height: 95vh;
}
.charges-bg-mobile {
  background: url("../../assets/kiosks/charges-mobile.jpg") no-repeat top
    right/cover;
  min-height: 395px;
}

.kiosks-btn-outline-light {
  color: #f8f9fa;
  border: 3px solid #f8f9fa;
  width: 400px;
}

.kiosks-btn-shadow {
  -moz-box-shadow: inset 0 0 10px rgba(0, 0, 0, 1);
  -webkit-box-shadow: inset 0 0 10px rgba(0, 0, 0, 1);
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 1);
}

.charges-overlay {
  background-color: rgba(255, 255, 255, 0.8);
}

.works-overlay {
  background-color: rgba(255, 255, 255, 0.85);
}

.send-cash-overlay {
  background-color: rgba(0, 0, 0, 0.2);
  min-height: 95vh;
}

.contact-us-overlay {
  background-color: rgba(0, 0, 0, 0.5);
  /* min-height: 100vh; */
}

@media only screen and (max-width: 575px) {
  .kiosks-btn-outline-light {
    color: #f8f9fa;
    border: 3px solid #f8f9fa;
    width: 310px;
  }
}

@media only screen and (max-width: 992px) {
  .send-cash-overlay {
    background-color: rgba(0, 0, 0, 0.3);
    min-height: 95vh;
  }

  .kiosk-location-card {
    height: 200px;
  }
}

@media only screen and (max-width: 770px) {
  .kiosk-bills-icon {
    width: 150px;
  }

  .kiosk-bills-text {
    font-size: 40px !important;
  }
}

@media only screen and (max-width: 540px) {
  .kiosk-bills-icon {
    width: 120px;
  }
}

@media only screen and (max-width: 400px) {
  .kiosk-location-card {
    height: 150px;
  }
  .kiosk-location-card-text {
    font-size: 16px;
  }

  .address-text {
    font-size: 11px !important;
  }

  .kiosk-bills-icon {
    width: 85px;
  }

  .kiosks-bg-1 {
    background: url("../../assets/kiosks/2-mobile.jpg") no-repeat top left/cover;
    /* min-height: 95vh; */
  }

  .contact-us-overlay {
    background-color: rgba(0, 0, 0, 0);
    /* min-height: 95vh; */
  }
}

.kiosk-gradient-text {
  background-image: linear-gradient(to right, #236c78, #5cd0c0);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
@media (max-width: 960px) {
  .kiosk-gradient-text {
    background-image: linear-gradient(to right, #f02a2a, #ca3a0e);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    padding-top: 60px;
  }
}

.kiosk-bills-gradient-text {
  background-image: linear-gradient(to right, #f28907, #bf5517);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.kiosk-bills-text {
  font-size: 50px;
}

.charges-card-header {
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  background-color: #152e51;
}

.step-circle {
  height: 50px !important;
  width: 50px !important;
  /* position: absolute;
    left: 25px; */
  z-index: 9;
}

.step-step {
  height: 60px !important;
  width: 200px !important;
  position: absolute;
  left: 25px;
  /* z-index: 0; */
}

.border-bottom-green-1 {
  border-bottom: 1.5px solid #005a5f !important;
}

.form-control:focus {
  box-shadow: none;
  border-bottom: 1.5px solid #cd5f00 !important;
}

.green-gradient-bg-1 {
  background-image: linear-gradient(
    to left,
    /* #365945,
      #385e48,
      #3a624b,
      #3c674f, */ #3e6c52,
    #407054,
    #417557,
    #437959,
    #457e5c,
    #48825e,
    #4a8761,
    #4d8c63
  );
}

.blue-gradient-bg-1 {
  background-image: linear-gradient(
    to left,
    #54bfac,
    #4db5a7,
    #46aca1,
    #40a29b,
    #3b9995,
    #379290,
    #338b8b,
    #308486,
    #2d7e81,
    #29777d,
    #277178,
    #246b73
  );
}

.scroll-container {
  overflow-y: scroll;
  max-height: 120vh;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px lightgrey;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: lightgrey;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: lightgrey;
}
/* Styles for screens with a width of 800px or more */
@media (min-width: 1000px) {
  .kiosk-end {
    margin-right: 0px;
    margin-left: 35%;
    padding-top: 200px;
  }
}

/* Styles for screens with a width less than 800px */
@media (max-width: 1000px) {
  .kiosk-end {
    margin-right: 0px;
    padding-right: 0;
    margin-left: 0px;
  }
}
.kiosk-container{
  margin:0px;
}